import React from "react";
import { Link, navigate } from "gatsby";
import styled from "styled-components";
import media from "styled-media-query";

const Button = ({
  color, color2, words, to, type, submit
}) =>{
  return (
    <StyledButton color={color} color2={color2} onClick={()=>{navigate(to)}} type={type} submit={submit}>{words}</StyledButton>
  );
};

export default Button;

const StyledButton = styled.button.attrs(props => ({
  color: props.color,
  color2: props.color2,
}))`
  background: ${props => props.color};
  border: none;
  border-radius: 10px;
  outline: none;
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 700;
  font-size: 14pt;
  color: ${props => props.color2};

  &:hover {
    background: rgba(0,0,0,0);
    border: 1px ${props => props.color} solid;
    border-radius: 10px;
    color: ${props => props.color};
    pointer: cursor;
  }
  ${media.lessThan("medium")`
    /* screen width is less than 768px (medium) */
    width: 60vw;
    height: 8vh;
    margin: 0 auto 3vh auto;
  `}
  ${media.greaterThan("medium")`
    /* screen width is greater than 1170px (large) */
    width: 20vw;
    height: 10vh;
    margin: 5vh 0 10vh 0;
  `}
`;