import React from "react";
import { Breadcrumb } from "gatsby-plugin-breadcrumb"
import '../../components/Breadcrumb.css'
import styled from "styled-components";
import media from "styled-media-query";

import Layout from "../../components/Layout";
import Button from "../../components/Button";
import Facilities from "../../components/Facilities";

import MainImage1 from "../../img/torii-3.jpg";
import MainImage2 from "../../img/torii-4.jpg";
import MainImage3 from "../../img/torii-5.jpg";
import BgImage1 from "../../img/news-bg.png";

const ToriiKuguruPage = ({ pageContext }) => {
  const { breadcrumb: { crumbs }} = pageContext;

  return (
    <Layout>
      {/*
      パンくず
      メインビジュアル
      タイトル
      2カラム紹介
      2カラムボタン
      詳細施設案内
      Googleマップ埋め込み
      共通の施設案内
      パンくず
      */}
      <Body>
      <Breadcrumb crumbs={crumbs} crumbSeparator="/" crumbLabel="Guesthouse&Lounge とりいくぐる"  />
      <ImageBig src={MainImage1}></ImageBig>
      <MidashiBg>
        <Komidashi>Guesthouse&Lounge</Komidashi>
        <Midashi>とりいくぐる</Midashi>
      </MidashiBg>
      <p>
        商店街の西端にひっそりとたたずむ、鳥居をくぐって入る不思議なゲストハウス。<br />
        おいしいごはんとあたたかな人の気配。家に来たような気持ちでゆっくりとお過ごしください。
      </p>
      <Intro2ColumnDiv>
          <Column>
              <ImageSmall src={MainImage2}></ImageSmall>
              <Komidashi>日常に旅を</Komidashi>
              <p>地元の人でも気軽にゲストハウスに来ることができるように、泊まらなくてもOKの日中滞在のプランをご用意。<br />
                 とりいをくぐると、自由に散策できる公園のような中庭と畑があります。</p>
          </Column>
          <Column>
              <Komidashi>光が差し、庭がみえるラウンジの朝</Komidashi>
              <p>とりいくぐるには、中庭から差し込む朝の光がとてもきれいなラウンジがあります。<br />
                 時間があれば、泊まった次の日はラウンジでゆっくりと朝ごはんを。<br />
                 1泊の滞在だけでなく、連泊やフリーアコモデーションスタッフも受け付けています。</p>
                 <br />
                 <br />
              <ImageSmall src={MainImage3}></ImageSmall>
          </Column>
      </Intro2ColumnDiv>
      <Button2ColumnDiv>
          <Button color="#666666" color2="#ffffff" words="宿泊予約する" to="/form/stay"></Button>
      </Button2ColumnDiv>
      <DetailDiv>
          <Nakamidashi>Guesthouse&Lounge とりいくぐる</Nakamidashi>
          <br />
          <p>
            〒700-0026　岡山市北区奉還町4丁目 7-15<br />
            mail : info[@]toriikuguru.com <br />
            TEL : 086-250-2629 <br />
            Check-in 16:00-21:00 / Check-out -10:00 <br />
            day-time stay 11:00-15:00<br />
            toriikuguru.com
          </p>
      </DetailDiv>
      <iframe src="https://www.google.com/maps?output=embed&z=16&ll=34.668877,133.9075778&q=とりいくぐる" width="100%" height="300px" frameborder="0" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
      <br />
      </Body>
      <Facilities />
      <FooterDiv>
        <Breadcrumb crumbs={crumbs} crumbSeparator="/" crumbLabel="Guesthouse&Lounge とりいくぐる"  />
      </FooterDiv>
    </Layout>
  );
}

export default ToriiKuguruPage;

const Body = styled.div`
  margin: 0 5vw 3em 5vw;
`;

const MidashiBg = styled.div`
  width: 100%;
  background-image: url(${BgImage1});
  background-repeat: no-repeat;
  background-position: 50% 100%;
  background-size: 100%;
  text-align: center;
  ${media.lessThan("medium")`
    /* screen width is less than 768px (medium) */
    height: 8em;
    margin: 0 0 2em 0;
    padding: 2em 15vw 0 15vw;
  `}
  ${media.greaterThan("medium")`
    /* screen width is greater than 1170px (large) */
    height: 15em;
    margin: 0 0 5em 0;
    padding: 5em 15vw 0 15vw;
  `}
`;

const Intro2ColumnDiv = styled.div`
  ${media.lessThan("medium")`
    /* screen width is less than 768px (medium) */
    width: 90vw;
    margin: 5vh 0 0 0;
  `}
  ${media.greaterThan("medium")`
    /* screen width is greater than 1170px (large) */
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 12vh 0 0 0;
  `}
`;

const Button2ColumnDiv = styled.div`
  ${media.lessThan("medium")`
    /* screen width is less than 768px (medium) */
    display: flex;
    flex-direction: column;
    width: 90vw;
    margin: 0 auto 0 auto;
  `}
  ${media.greaterThan("medium")`
    /* screen width is greater than 1170px (large) */
    display: flex;
    justify-content: space-between;
    width: 50%;
    margin: 5vh auto 0 auto;
  `}
`;

const FlexA = styled.a`
  display: flex;
`;

const DetailDiv = styled.div`
  height: 40vh;
  background: #ffffff;
  border-radius: 10px;
  margin: 0 auto 5vh auto;
  padding: 3vh 3vw 3vh 3vw;
  ${media.lessThan("medium")`
    /* screen width is less than 768px (medium) */
    width: 90vw;
  `}
  ${media.greaterThan("medium")`
    /* screen width is greater than 1170px (large) */
    width: 50%;
  `}
`;

const FooterDiv = styled.div`
  margin: 3vh 5vw 3vh 5vw;
`;

const Column = styled.div`
  ${media.lessThan("medium")`
    /* screen width is less than 768px (medium) */
    width: 90vw;
    margin: 0 0 10vh 0;
  `}
  ${media.greaterThan("medium")`
    /* screen width is greater than 1170px (large) */
    width: 40vw;
  `}
`;

const ImageBig = styled.img`
  width: 100%;
  border-radius: 10px;
  margin: 0 0 3vh 0;
  object-fit: cover;
  ${media.lessThan("medium")`
    /* screen width is less than 768px (medium) */
    height: 30vh;
  `}
  ${media.greaterThan("medium")`
    /* screen width is greater than 1170px (large) */
    height: 80vh;
  `}
`;

const ImageSmall = styled.img`
  width: 100%;
  border-radius: 10px;
  object-fit: cover;
  ${media.lessThan("medium")`
    /* screen width is less than 768px (medium) */
    height: 30vh;
    margin: 0 0 3vh 0;
  `}
  ${media.greaterThan("medium")`
    /* screen width is greater than 1170px (large) */
    height: 50vh;
    margin: 0 0 10vh 0;
  `}
`;

const Midashi = styled.p`
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 700;
  ${media.lessThan("medium")`
    /* screen width is less than 768px (medium) */
    font-size: 20pt;
  `}
  ${media.greaterThan("medium")`
    /* screen width is greater than 1170px (large) */
    font-size: 28pt;
  `}
`;

const Komidashi = styled.p`
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 700;
  font-size: 18pt;
  ${media.lessThan("medium")`
    /* screen width is less than 768px (medium) */
    font-size: 14pt;
    margin: 0 0 1vh 0;
  `}
  ${media.greaterThan("medium")`
    /* screen width is greater than 1170px (large) */
    font-size: 18pt;
    margin: 0 0 5vh 0;
  `}
`;

const Nakamidashi = styled.p`
font-family: 'Noto Sans JP', sans-serif;
font-weight: 700;
font-size: 14pt;
text-align: center;
`;
